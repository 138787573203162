import { getLegalPage, getLegalPages } from "../core/data";

import Errors from "../components/Errors";
import Head from "next/head";
import LegalPage from "../components/LegalPage";
import NotFound from "../components/NotFound";

function Legal({ data, errors }) {
  if (errors.length) {
    return <Errors errors={errors} />;
  }
  if (!data.wagtail.legalPage) return <NotFound />;

  const { title, content } = data.wagtail.legalPage;

  return (
    <>
      <Head>
        <title>Trailstone | {title}</title>
      </Head>
      <LegalPage data={data} />
    </>
  );
}

export default Legal;

export async function getStaticProps({ params, locale }) {
  const { data, errors } = await getLegalPage(params.slug, locale);
  return { props: { data, errors }, revalidate: 60 };
}

export async function getStaticPaths({ defaultLocale }) {
  const { data, errors } = await getLegalPages(defaultLocale);

  const paths = errors.length
    ? []
    : data.wagtail.legalPages.map((legalPage) => {
        return `/${legalPage.slug}`;
      });

  return {
    paths,
    fallback: "blocking",
  };
}
