import { color, space, typography } from "styled-system";

import styled from "styled-components";

const RichTextBase = styled.div`
  ${color}
  ${typography}
  ${space}
  & p, li {
    line-height: 25px;
    font-size: 15px;
  }
  & *:not(:first-child) {
    margin-top: 40px;
  }
  & b {
    font-family: "EuclidCircularB-Bold";
  }
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-size: 20px;
  }
  & ul {
    margin-left: 20px;
  }
  & li {
    list-style: disc !important;
    margin-top: 10px !important;
  }
  & a {
    text-decoration: underline;
    transition: color 300ms;
    &:hover {
      color: #c7ff00;
    }
  }
  @media (min-width: 960px) {
    & p,
    li {
      line-height: 28px;
      font-size: 18px;
    }
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      font-size: 30px;
    }
  }
`;

function RichText({ children, ...props }) {
  return (
    <RichTextBase
      {...props}
      dangerouslySetInnerHTML={{ __html: children }}
    ></RichTextBase>
  );
}
export default RichText;
