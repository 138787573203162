import AnimatedHeadingGradientLast from "./AnimatedHeadingGradientLast";
import BodyTextLarge from "./BodyTextLarge";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import CapSize from "./CapSize";
import FadeInItem from "./FadeInItem";
import GradientLine from "./GradientLine";
import NarrowCol from "./NarrowCol";

import SlideUpItem from "./SlideUpItem";
import { useStartAnim } from "../core/hooks";

function PageIntro({ smallHeading, mainHeading, subheading, children }) {
  const startAnim = useStartAnim();
  const animationStaggerAmount = 150;
  const baseDelay = 350;
  const lineDelay = animationStaggerAmount + baseDelay;
  const smallHeadingDelay = lineDelay + animationStaggerAmount;
  let headingDelay;
  // There may not be a small heading, if this is the case the the small
  // heading delay gets used for the heading
  if (smallHeading) {
    headingDelay = smallHeadingDelay + animationStaggerAmount;
  } else {
    headingDelay = smallHeadingDelay;
  }

  // The optimum subheading delay will depend on the duration of the heading
  // animation, which can vary in length
  const headingStaggerAmount = 30;
  const subheadingDelay =
    mainHeading.split(" ").length * headingStaggerAmount +
    headingDelay +
    animationStaggerAmount;

  return (
    <BoxWithGutter
      pt={smallHeading ? [120, null, 220, null] : [170, null, 280, null]}
      pb={[60, null, 120, null]}
    >
      <NarrowCol zIndex={2}>
        <Box width="100%" position="relative">
          <Box pb={[null, null, 22, 22]}>
            {smallHeading && (
              <SlideUpItem
                animate={startAnim}
                animationDelay={smallHeadingDelay}
              >
                {smallHeading}
              </SlideUpItem>
            )}
            <GradientLine animate={startAnim} animationDelay={lineDelay} />
          </Box>
          <AnimatedHeadingGradientLast
            text={mainHeading}
            baseDelay={headingDelay}
            animate={startAnim}
          />
          {subheading && (
            <FadeInItem animate={startAnim} animationDelay={subheadingDelay}>
              <BodyTextLarge pt={20}>{subheading}</BodyTextLarge>
            </FadeInItem>
          )}
        </Box>
      </NarrowCol>
      <FadeInItem
        animate={startAnim}
        animationDelay={
          subheading
            ? subheadingDelay + animationStaggerAmount
            : subheadingDelay
        }
      >
        {children}
      </FadeInItem>
    </BoxWithGutter>
  );
}

export default PageIntro;
