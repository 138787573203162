import Arrow from "../assets/arrow.svg";
import Box from "./Box";
import CapSize from "./CapSize";

function BackButtonInner({ children }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      position="absolute"
      color="white"
      hover="color: #C7FF00;"
      left={[23, 50, 70, null]}
      top={[80, null, 120, null]}
      zIndex={5}
    >
      <Box
        transform="rotate(180deg)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Arrow />
      </Box>
      <CapSize
        pl="10px"
        transition="color 300ms"
        color="inherit"
        fontFamily="medium"
        fontSize={18}
      >
        {children}
      </CapSize>
    </Box>
  );
}

export default BackButtonInner;
