import BackButtonInner from "./BackButtonInner";
import Button from "./Button";
import { useRouter } from "next/router";

function BackButton() {
  const router = useRouter();
  return (
    <Button onClick={() => router.back()}>
      <BackButtonInner>Back</BackButtonInner>
    </Button>
  );
}

export default BackButton;
