import BodyTextLarge from "../components/BodyTextLarge";
import Box from "../components/Box";

function NotFound() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flex={1}>
      <BodyTextLarge>404 - Page Not Found</BodyTextLarge>
    </Box>
  );
}

export default NotFound;
