import BackButton from "./BackButton";
import Box from "./Box";
import BoxWithGutter from "./BoxWithGutter";
import FadeInItem from "./FadeInItem";
import PageIntro from "./PageIntro";

import RichText from "./RichText";

function LegalPage({
  data: {
    wagtail: { legalPage },
  },
}) {
  const { title, content } = legalPage;

  return (
    <>
      <BackButton />
      <PageIntro mainHeading={title} />
      <BoxWithGutter pb={[60, null, 160, null]}>
        <Box maxWidth={750} mx="auto" mt={-60}>
          <FadeInItem animate={true} animationDelay={900}>
            <RichText>{content}</RichText>
          </FadeInItem>
        </Box>
      </BoxWithGutter>
    </>
  );
}

export default LegalPage;
